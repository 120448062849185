<template>
    <div class="expande bg">
        <h1 class="fonte orange--text">Nova Senha!</h1>
        <v-card outlined color="#333" dark class="pa-4" width="600">
            <v-form ref="form">
                <v-flex xs12>
                    <v-text-field
                    :rules="[v => !!v || 'Preencha aqui']"
                    rounded solo-inverted type="password" dense flat v-model="nova_senha" label="Informe a nova senha aqui" :color="$primaria" />
                </v-flex>
            </v-form>
            <v-flex xs12>
                <v-btn rounded block class="white--text" :color="$theme.primary" @click="validate">  alterar senha <v-icon class="mb-1" size="20">mdd-check</v-icon> </v-btn>
            </v-flex>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['token'],
    data() {
        return {
            nova_senha: ""
        }
    },
    methods: {
        ...mapActions(['sendForgotPasswordFire']),
        validate() {
            if(this.$refs.form.validate()) {
                this.sendForgotPasswordFire({
                    token: this.token,
                    nova_senha: this.nova_senha
                })
            }
        }
    },
    created() {
        this.verificaConta()
    }

}
</script>

<style>
    .expande {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>